.dialog
  position: fixed
  left: 0
  top: 0
  bottom: 0
  right: 0
  width: 100vw
  z-index: 9999
  .dialog-shell
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: var(--color-shell)
    backdrop-filter: blur(4.5px)
    &.light
      background: rgba(0,0,0,0)
      backdrop-filter: none
  .dialog-content
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%) translateZ(0)
    transition: all 0.2s ease-out
    &.center
      transform: translate(-50%,-50%) scale(0) translateZ(0)
      &.active
        transform: translate(-50%,-50%) scale(1) translateZ(0)
@media (max-width: 500px)
    .dialog-content.bottom
      top: initial
      bottom: 0
      left: 0
      transform: translate(0,100%) translateZ(0)
      width: calc(100%) !important
      max-width: initial !important
    .dialog-content
      &.bottom
        &.bottom
          &.active
            transform: translate(0,0) translateZ(0)

@media (max-height: 380px)
    .dialog .dialog-content
      overflow: auto
      background: #fff
