.profile-edit-from
  margin-top: 20px
  .input-area
    margin-bottom: 27px
    .input-area-des
      color: #999
      font-size: 12px
      margin-top: 12px
    .input-area-title
      font-size: 14px
      font-weight: 600
      line-height: 22px
      color: var(--color-text-main)
      margin-bottom: 10px
      margin-left: 10px
    .input-area-btn
      display: flex
      align-items: center
      justify-content: space-between
      border-radius: 16px
      background: var(--fill-1-light-disabled,#F8F9F8)
      height: 44px
      padding: 0 10px
      width: 100%
      box-sizing: border-box
      margin-bottom: 12px
      cursor: pointer
      .btn-label
        color: var(--text-4-headline,#272928)
        font-size: 14px
        font-style: normal
        font-weight: 600
        line-height: 22px
      .btn-extra
        display: flex
        align-items: center
        color: var(--text-4-headline,#272928)
        text-align: right
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: 22px
        span
          margin-right: 6px
