.event-detail {
  position: relative;
  background: var(--color-page-bg-1);
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;

  .mobile-item {
    display: none
  }

  @media(max-width: 450px) {
    .mobile-item {
      display: block;
    }
  }

  .event-detail-content {
    padding: 12px;
    display: flex;
    flex-flow: row nowrap;
    max-width: 1020px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: auto;
    width: 100vw;
    flex: 1;
    padding-bottom: 60px;
  }

  .event-share-btn {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--color-page-bg);
    margin-top: -8px;
    margin-right: 12px;
    cursor: pointer;
  }

  > div:first-child {
    margin-left: 12px;
  }

  .cover {
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .detail {
    padding: 23px 0;

    .pending {
      color: #E7C54E;
      background: #FFF7E8;
      font-weight: normal;
      padding: 4px 8px;
      font-size: 16px;
      border-radius: 6px;
      margin-right: 12px;
      position: relative;
      display: inline-block;
      height: 20px;
      transform: translateY(-5px);
      line-height: 20px;
    }

    .private {
      color: #c863ff;
      background: #f8f2ff;
      font-weight: normal;
      padding: 4px 8px;
      font-size: 16px;
      border-radius: 6px;
      margin-right: 12px;
      position: relative;
      display: inline-block;
      height: 20px;
      transform: translateY(-5px);
      line-height: 20px;
    }

    .cancel {
      color: #ffffff;
      background: #bdbdbd;
      font-weight: normal;
      padding: 4px 8px;
      font-size: 16px;
      border-radius: 6px;
      margin-right: 12px;
      position: relative;
      display: inline-block;
      height: 20px;
      transform: translateY(-5px);
      line-height: 20px;
    }

    @media (min-width: 450px) {
      & {
        padding-top: 0;
      }
    }

    .name {
      color: var(--Text-4-Headline, #272928);
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
    }

    .detail-item {
      display: flex;
      flex-flow: row wrap;
      color: var(--color-text-main) !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      align-items: center;
      margin-top: 32px;

      > div, > a {
        max-width: 80%;
        display: block;
        text-decoration: none;
        color: var(--color-text-main) !important;

        .main {
          color: var(--Fill-6-Dark, #272928);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sub {
          color: var(--Text-2-Secondary, #7B7C7B);
          font-size: 16px;
          max-width: 500px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        .repeat {
          color: var(--Text-2-Secondary, #7B7C7B);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;

          &:hover {
            color: #6CD7B2;
          }
        }
      }

      i {
        margin-right: 8px;
        padding: 10px;
        border-radius: 4px;
        border: 0.5px solid var(--Line-1-Light, #F1F1F1);
      }
    }

    a.detail-item {
      text-decoration: none;
    }

    .label {
      margin-top: 12px;
    }

    .hoster {
      margin: 16px 0;
      border-top: 1px solid var(--color-box-border);
      border-bottom: 1px solid var(--color-box-border);
      padding: 13px 0;

      .slide {
        display: block;
        flex-flow: row nowrap;
        width: auto;
        white-space: nowrap;
      }

      .host-item {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        white-space: nowrap;
        margin-right: 30px;
        cursor: pointer;
        color: var(--color-text-sub);


        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          margin-right: 8px;
        }

        .host-name {
          color: var(--color-text-main);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }

    .event-badge {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0 15px 0;
      padding: 10px;
      width: 100%;
      background: #f3f3f3;
      border-radius: 8px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      box-sizing: border-box;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .event-des {
      color: var(--color-text-main);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    .event-tab {
      margin-top: 28px;

      .tab-titles {
        width: 100%;
        padding-top: 3px;

        .center {
          display: flex;
          align-items: center;
        }

        .split {
          min-width: 1px;
          max-width: 1px;
          background: #F1F1F1;
          height: 37px;
        }

        .tab-title {
          flex: 1;
          text-align: center;
          padding: 13px 0;
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
          color: var(--Fill-6-Dark, #272928);
          font-weight: 600;

          @media(max-width: 650px) {
            & {
              min-width: 48%;
              max-width: 48%;
              font-size: 12px;
              flex-grow: 0;
            }
          }

          > div {
            z-index: 2;
            position: relative;
          }

          &.active::before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10px;
            width: 90px;
            height: 12px;
            flex-shrink: 0;
            border-radius: 8px;
            background: linear-gradient(88deg, #BAFFAD -2.09%, rgba(161, 244, 230, 0.48) 62.09%, rgba(128, 248, 192, 0.00) 97.29%);
          }
        }
      }

      .tab-contains {
        padding: 12px 0 0 0;
      }
    }

    .wechat-title {
      color: var(--color-text-main);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */

      a {
        color: #58a7ff;
        cursor: pointer;
      }
    }

    .wechat-account {
      color: var(--color-text-main);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      margin-bottom: 12px;

      span {
        color: #58a7ff;
        cursor: pointer;
      }

      a {
        color: #58a7ff;
        cursor: pointer;
      }
    }

    .wechat-contact-group {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 30px;
      height: 172px;
      overflow: hidden;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .event-action {
      width: 100%;
      padding: 10px 0;

      .can-not-access {
        text-align: center;
        height: 50px;
        line-height: 50px;
        color: var(--text-1-disabled, #C3C7C3);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }

      .center {
        display: flex;
        margin-top: 12px;

        button {
          margin: 0 6px;
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }

    .min-participants-alert {
      margin-bottom: 12px;
    }
  }

  .spinner-bar {
    height: 200px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
}

.event-top-btn {
  display: inline-flex;
  flex-flow: row nowrap;

  button {
    white-space: nowrap;
    display: inline-flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    margin-left: 12px;

    @media (max-width: 450px) {
     & {
       font-size:  14px;
     }
    }

    svg {
      margin-right: 6px;
    }
  }


  a {
    white-space: nowrap;
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Fill-2-Medium, #F2F2F1);
    color: var(--Text-4-Headline, #272928);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    margin-left: 12px;

    img, i {
      margin-right: 6px;
    }

    @media (max-width: 450px) {
      img, i {
        margin-right: 0;
      }

      & span {
        display: none;
      }
    }
  }
}

.switch-preview-map {
  margin-bottom: 6px;
  color: #7492EF !important;
  cursor: pointer;
}

.map-action {
  margin-left: 46px;
  display: flex;
  flex-flow: nowrap;
  flex-flow: row nowrap;

  >div {
    margin-right: 12px;
  }
}

.venue-link {
  width: calc(100% - 46px);
  margin-left: 46px;
  margin-bottom: 6px;
  color: #7492EF !important;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #7492EF !important;
    font-size: 16px;
  }
}

.map-preview.full {
  width: 100%;
  margin-left: 0;
}

.map-preview {
  width: calc(100% - 46px);
  position: relative;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 46px;
  margin-bottom: 28px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2.71px;
    border: 0.339px solid var(--Fill-6-Dark, #272928);
    background: var(--Fill-6-Dark, #272928);
    color: var(--Text-0-White, #FFF);
    font-size: 12px;
    padding: 4px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.552px;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.event-login-status {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--Fill-2-Medium, #F2F2F1);
  background: #FFF;
  margin-top: 30px;
  margin-bottom: 30px;

  .link {
    color: var(--Flowkit-Charcoal, #222);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &:hover {
      text-decoration: underline;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-left: 11px;
    }
  }

  .user-info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  .des {
    color: var(--Flowkit-Charcoal, #222);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0;
    word-break: break-word;
  }
}

.event-detail-content-main {
  flex: 1px;
  max-width: 100%;

  .event-login-status, .home-login-panel {
    display: none;
  }

  @media (min-width: 450px) {
    & {
      max-width: 637px;
    }

    .cover {
      display: none;
    }
  }

  @media (max-width: 450px) {
    .event-login-status {
      display: block;
    }

    .home-login-panel {
      display: flex;
    }

  }
}

.event-detail-content-site {
  max-width: 324px;
  min-width: 324px;
  margin-left: 35px;

  .event-action {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    button {
      font-size: 12px;
      margin: 0 6px;
    }
  }

  @media (max-width: 450px) {
    display: none;
  }

  .cover {
    margin-bottom: 30px;
  }

  .event-login-status {
    .event-action {
      justify-content: space-between;

      button {
        margin-bottom: 12px;
        margin-left: 0;
        margin-right: 0;
        white-space: nowrap;
      }

      button:not(:first-child) {
        margin-left: 12px;
      }

      button:nth-child(3) {
        min-width: 100%;
      }
    }
  }
}

.event-detail-head {
  max-width: 1020px;
  width: 100%;
  padding-bottom: 12px;
  margin: 0 auto !important;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;

  .event-detail-head-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 12px;
    align-items: center;

    .event-detail-head-event-home {
      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        text-decoration: none;
        color: #272928;

        &:hover {
          text-decoration: underline;
        }

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 500px) {
            max-width: 120px;
          }
        }

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 8px;
        }
      }
    }
  }
}

.dialog-repeat-event-list {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);

  .close-btn {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #F8F8F8;
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #9B9B9B;
  }

  .title {
    margin-top: 10px;
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .repeat-event-list {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 12px;

    a {
      padding: 18px;
      border-radius: 16px;
      background: var(--Fill-2-Medium, #F5F8F6);
      margin-bottom: 8px;

      color: #272928;
      font-size: 12.794px;
      font-style: normal;
      font-weight: 600;
      text-decoration: none;

      &.active {
        color: #6CD7B2;
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.btns {
  display: flex;
  flex-flow: row nowrap;

  button {
    flex: 1;
  }

  button:not(:first-child) {
    margin-left: 8px;
  }
}

.dialog-permission {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 32px 15px 20px;
  box-sizing: border-box;

  .des {
    font-weight: bold;
    font-size: 16px;
  }
}
