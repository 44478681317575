.app-tips
  width: 25px
  height: 25px
  margin-left: 8px
  cursor: pointer
.app-tips-content
  border-radius: 1px
  background: var(--unnamed,#303133)
  color: var(--unnamed,#fff)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 18px
