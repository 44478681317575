.badge-detail-bg
  width: 100%
  height: 100%
  position: absolute
  background: #fff
  z-index: 1
  overflow: hidden
  border-radius: 12px
  .ball1
    position: absolute
    width: 247px
    height: 247px
    left: -125px
    bottom: 57px
    background: #9EFEDD
    opacity: 0.3
    filter: blur(24px)
  .ball2
    position: absolute
    width: 197px
    height: 197px
    left: 284px
    top: 214px
    background: #F3DFAB
    opacity: 0.3
    filter: blur(24px)
    border-radius: 50%
.container
  min-width: 330px
  width: 100%
  z-index: 2
  display: flex
  flex-direction: column
  align-items: center
  box-sizing: border-box
  position: relative
  padding: 12px 12px 24px 12px
.detail-flex-box
  display: flex
  flex-flow: column nowrap
  width: 100%
