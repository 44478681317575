.image-detail-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .close-btn {
    width: 28px;
    height: 28px;
    left: 12px;
    top: 12px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
  }

  .actions {
    position: absolute;
    bottom: 100px;
    width: 100%;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    button {
      min-width: 140px;
      max-width: 50%;

      .icon-download {
        margin-right: 8px;
        font-weight: bold;
      }
    }
  }
}
