.beast-svg-wrapper {
  width: 286px;
  height: 272px;
  border-radius: 28px;
  background: #F8F5F2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
  }
}
