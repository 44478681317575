.badge-creator-tag
  display: inline-flex
  padding: 8px 16px
  background: #F8F9F8
  border-radius: 51px
  flex-flow: row nowrap
  align-items: center
  justify-content: center
  text-decoration: none
  width: fit-content
  .label
    font-weight: 600
    font-size: 14px
    line-height: 22px
    color: #272928
    margin-right: 8px
  img
    height: 16px
    width: 16px
    border-radius: 50%
    margin-right: 4px
  .username
    font-weight: 400
    font-size: 14px
    line-height: 22px
    color: #4F5150
