.dialog-notification {
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
}

.notification-item {
    padding: 14px 12px;
    border-bottom: 1px solid var(--line-1-light, #F1F1F1);
    background: #FFF;
    text-decoration: none;
    max-height: 100px;
    min-height: 15px;
    transition: max-height 0.5s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.active {
    max-height: 300px;
}

.center {
    max-width: var(--width-page-max);
    padding: 0 12px 12px 12px;
    box-sizing: border-box;
    width: 100vw;
    margin: 0 auto;
}

.notification-list {
    flex: 1;
    overflow: auto;
    max-width: var(--width-page-max);
    margin: 0 auto;
    width: 100vw;
}

.notification-item-profile {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-decoration: none;
    color: var(--text-4-headline, #272928);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 8px;
    justify-content: space-between;
}

.left {
    display: flex;
    flex-flow: row nowrap;
}

.is-new {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #F26692;
}

.notification-item-profile-time{
    margin-left: 12px;
    color: #595959;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.avatar {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 6px;
}

.notification-item-profile-info {
    display: flex;
    flex-flow: row nowrap;
}

.notification-item-message {
    color: var(--Settings-first-title, #222);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.text {
    margin-bottom: 10px;
}

.link {
    color: var(--flowkit-blue, #09F);
}

.action {
    padding: 12px;
}
