.event-calendar {
  height: 190px;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
  width: 100%;

  &.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    top: 46px;
    box-sizing: border-box;
    z-index: 99;
    background: #F5F8F6;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-right: 12px;

  }

  .fixed {
    width: 100%;
    position: fixed;
    left: 0;
    top: 46px;
    box-sizing: border-box;
    z-index: 99;
    background: #F5F8F6;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-right: 12px;
  }

  .inner {
    margin: 0 auto;
    max-width: 375px;
    position: relative;
  }

  &.active {
    height: 400px;
  }

  .calendar-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    .day-item {
      min-height: 50px;
      min-width: 50px;
      border-radius: 50%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: var(--color-text-main);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      position: relative;

      &.active {
        background: #6CD7B2!important;
        color: #fff!important;
      }

      &.curr {
        background: #f1f1f1;
      }

      &.has-event:after {
        display: block;
        content: '.';
        position: absolute;
        bottom: 4px;
        font-size: 24px;
      }
    }
  }

  .day-name {
    .day-item {
      color: var(--content-content-tertiary, #5E5E5E);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      pointer-events: none;
    }
  }

  .swiper-slide {
    max-height: 300px;
  }

  .event-calendar-mouth {
    height: 300px;
  }

  .event-calendar-swiper {
    height: 300px;
  }

  .swiper {
    width: 100%;
    height: 300px;
  }

  .action-bar {
    padding: 12px 6px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 375px;

    .month-date {
      color: var(--color-text-main);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .toggle-btn {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 8px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 88;
  }

  .week-mode {
    padding-bottom: 40px;
  }
}
