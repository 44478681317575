.amount-input
  background: var(--color-page-bg)
  border-radius: 10px
  padding: 14px 12px
  .title
    font-weight: 400
    font-size: 14px
    line-height: 22px
    color: #272928
    margin-bottom: 15px
  .row
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: center
    margin-bottom: 23px
  .input-box
    background: #fff
    border: .5px solid #ceced3
    border-radius: 8px
    line-height: 40px
    width: 103px
    margin-right: 8px
    text-align: center
    font-weight: 600
    font-size: 20px
    color: #3c3e3d
    outline: none
  .btn
    cursor: pointer
    padding: 0 12px
    box-sizing: border-box
    height: 40px
    line-height: 40px
    background: #fff
    border-radius: 8px
    text-align: center
    min-width: 60px
    margin-right: 12px
    font-weight: 600
    font-size: 16px
    color: #3c3e3d
    -webkit-user-select: none
    -moz-user-select: none
    user-select: none
    &.active
      background: #effff9
      border: 1px solid #9efedd
