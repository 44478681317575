.dashboard-page {
  min-height: calc(100vh - 48px);
  background: var(--color-page-bg-1);
  position: relative;

  .center {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 12px;
  }

  .setting-form {
    margin-top: 27px;
  }

  .setting-form-item {
    border-radius: 16px;
    background: var(--fill-1-light-disabled, #F8F9F8);
    display: flex;
    flex-flow: row nowrap;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-bottom: 12px;
    cursor: pointer;

    .label {
      color: var(--color-text-main);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .value {
      display: flex;
      color: var(--color-text-main);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      align-items: center;

      svg {
        margin-left: 8px;
      }
    }
  }
}

.dashboard-dialog {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100vh - 48px);
  overflow: hidden;
  background: var(--color-card-bg);
  padding: 0 0 12px 0;
  box-sizing: border-box;

  div[data-baseweb="select"] {

    >div {
      font-size: 14px;
      border: 0;
      color: var(--color-text-main);
      padding-left: 12px;
      border-radius: 12px;
      background: var(--color-input-bg);
    }
    div[value], input {
      font-size: 14px;
      border: 0;
      color: var(--color-text-main);
    }
  }

  .permission-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;

    color: var(--Text-4-Headline, #272928);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .permission-title {
    color: #272928;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;
  }

  .center {
    display: flex;
    flex-flow: column nowrap;
    height: calc(100vh - 48px);
  }

  .dashboard-dialog-head {
    padding-bottom: 16px;
  }

  .action-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    background: var(--color-page-bg-1);
    z-index: 99;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;


    button {
      width: 350px;
    }
  }

  .dialog-inner {
    padding: 20px 8px 180px 8px;
    flex: 1;
    overflow: auto;
    box-sizing: border-box;


    .dialog-des {
      margin-bottom: 24px;
    }

    .event-site-item {
      margin-bottom: 40px;
    }

    .event-site-item-title {
      margin-top: 12px;
      color: var(--color-text-main);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
    }
  }

  .add-event-site-btn {
    border-radius: 8px;
    background: var(--Fill-2-Medium, #F7F7F7);
    display: inline-flex;
    padding: 12px 18px;
    align-items: center;
    color: var(--color-text-main);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    margin-top: 12px;
    cursor: pointer;

    svg {
      margin-right: 4px;
    }
  }

  .input-area {
    margin-bottom: 20px;

    .issues-des {
      font-size: 12px;
      color: var(--color-text-main);
      margin-bottom: 15px;
      padding-left: 10px;
    }

    .input-area-des {
      color: #999;
      font-size: 12px;
      margin-top: 12px;
    }

    .input-area-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: var(--color-text-main);
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.venue-list-item {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 12px;
  align-items: center;

  .info {
    cursor: pointer;
    border-radius: 10px;
    background: var(--Fill-1-Light-Disabled, #F8F9F8);
    padding: 10px;
    flex: 1;
    margin-right: 12px;
    display: flex;
    flex-flow: row nowrap;
    font-size: 16px;
    align-items: center;
    >div {
      flex: 1;
    }

    i {
      font-size: 16px;
    }
  }

  >svg {
    cursor: pointer;
  }
}
