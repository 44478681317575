.dialog-connect-wallet
  background: #fff
  width: 100%
  height: 100%
  box-shadow: 0 1px 3px #c0c0c0
  box-sizing: border-box
  border-radius: 12px
  padding: 20px
  .error
    margin-top: 12px
    color: #ff8c8c
  .title
    color: var(--Text-4-Headline, #272928)
    margin-bottom: 24px
    font-size: 20px
    font-style: normal
    font-weight: 600
    line-height: normal
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    .icon-close
      padding: 4px
      border-radius: 50%
      background: #F8F8F8
      font-size: 12px
      height: 24px
      width: 24px
      min-width: 24px
      display: flex
      align-items: center
      justify-content: center
      color: #9B9B9B
      cursor: pointer
  .connect-item
    padding: 10px 0
    cursor: pointer
    display: flex
    align-items: center
    flex-flow: row nowrap
    justify-content: flex-start
    user-select: none
    position: relative
    &.items-start
      align-items: flex-start
    &.col
      flex-flow: column nowrap
      align-items: flex-start

    &.disable
      pointer-events: none
      opacity: 0.6
    &:last-child
      border: 0

    .tip
      font-size: 12px
      color: #7B7C7B
      margin-top: 4px
      font-weight: normal
      line-height: 18px
    img
      display: block
      width: 28px
      height: 28px
      margin-right: 16px
    .connect-name
      font-weight: 600
      font-size: 16px
      line-height: 24px
      letter-spacing: 0.02em
      color: #000000
    .connect-des
      color: #C6C9C5
      font-size: 12px
    .chain-icon
      position: absolute
      width: 18px!important
      height: 18px!important
      border-radius: 50%
      left: 16px
      top: 25px
  .wallet-adapter-dropdown
    .wallet-adapter-button
      background: none
      border: 0

@keyframes Spinner
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.spinner
    width: 20px
    height: 20px
    animation: Spinner infinite 0.8s linear
