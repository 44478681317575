.vote-tab-list
  .title-member
    display: flex
    justify-content: space-between
    align-items: center
    color: #000
    font-size: 14px
    line-height: 22px
    b
      font-size: 16px
      font-weight: 600
      margin-right: 5px
  .user-assets-list
    .list-content
      .vote-card
        max-width: initial
        width: 100%
        flex-basis: initial
