.event-location-input {
  .input-area-title {
    color: var(--Text-4-Headline, #272928);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-left: 12px;
    margin-bottom: 16px;
  }

  .input-area-sub-title {
    color: var(--Text-4-Headline, #272928);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-left: 12px;
  }

  #map {
    width: 0;
    height: 0;
    opacity: 0;
    display: block;
  }

  .search-input {
    position: relative;

    .search-res {
      position: absolute;
      width: 100%;
      border-radius: 18px;
      background: var(--color-card-bg);
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
      z-index: 99;
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;


      .res-list {
        max-height: 200px;
        overflow: auto;
      }

      .empty-list {
        height: 200px;
        overflow: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background: #f6f6f6;
        border-radius: 12px;
      }

      .search-res-item {
        padding: 12px;
        cursor: pointer;
        border-radius: 6px;

        &:hover {
          background: #F5F8F6;
        }

        .search-title {
          color: var(--color-text-main);
          font-size: 16px;
          margin-bottom: 4px;
        }
        .search-sub-title {
          color: var(--fill-5-dark, #7B7C7B);
          font-size: 12px;
        }
      }
    }
  }

  .error {
    margin-top: 12px;
    color: #ff8c8c;
    padding-left: 12px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-text-main);
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .selector {
    position: relative;
    margin-top: 12px;

    i {
      position: absolute;
      z-index: 9;
      left: 12px;
      font-size: 18px;
      top: 13px;
    }

    div[data-baseweb="select"] {

      >div {
        font-size: 14px;
        border: 0;
        color: var(--color-text-main);
        padding-left: 24px;
        border-radius: 12px;
        background: var(--color-input-bg);
      }
      div[value], input {
        font-size: 14px;
        border: 0;
        color: var(--color-text-main);
      }
    }
  }

  .delete {
    cursor: pointer;
    margin-right: -12px;
  }

  .custom-selector {
    position: relative;

    .shell {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: rgba(0,0,0,0);
      left: 0;
      top: 0;
      z-index: 9;
    }

    div[data-baseweb="input"] {
      position: relative;
      z-index: 99;
    }

    .search-res {
      position: absolute;
      width: 100%;
      border-radius: 18px;
      background: var(--color-card-bg);
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
      z-index: 99;
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;


      .res-list {
        height: 200px;
        overflow: auto;
        margin-top: 12px;
      }

      .empty-list {
        height: 200px;
        overflow: auto;
        margin-top: 12px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background: #f6f6f6;
        border-radius: 12px;
      }

      .search-res-item {
        padding: 12px;
        cursor: pointer;
        border-radius: 6px;

        &:hover {
          background: #F5F8F6;
        }

        .search-title {
          color: var(--color-text-main);
          font-size: 16px;
          margin-bottom: 4px;
        }
        .search-sub-title {
          color: var(--fill-5-dark, #7B7C7B);
          font-size: 12px;
        }
      }
    }
  }
}

.approval-tag {
  border-radius: 4px;
  background: var(--Secondary-Color-Brand5-1-Light, #EEF2FE);
  padding: 2px 7px 3px 5px;
  justify-content: center;
  color: var(--Secondary-Color-Brand5-3-Normal, #7492EF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: inline-block!important;
  margin: 4px 0;
}
