.flex-text-area-wrapper {
  position: relative;
  width: 100%;
  height: auto;


  .flex-text-area {
    width: 100%;
    background: var(--color-input-bg);
    border-radius: 12px;
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 12px;
    box-sizing: border-box;
    color: var(--color-text-main);

    &.error {
      border: 1px solid #FF4D4F;
    }

    .flex-text-area-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-top: 2px;
      margin-right: 6px;
    }

    .edit-box {
      flex: 1;
      background: none;
      border: none;
      font-family: 'Poppins', 'Noto Sans SC', sans-serif;
      font-size: 14px;
      outline: none;
      resize: none;
      height: auto;
      word-break: break-all;
      overflow: auto;
      position: relative;
      caret-color: var(--color-text-main);

      &.empty:before {
        content: attr(data-placeholder);
        left: 0;
        top: 0;
        color: #919191;
        position: absolute;
      }
    }
  }
}


