.dialog-badge-reason
  font-size: 14px
  color: #7b7c7b
  margin-bottom: 20px
  white-space: normal
  word-break: break-word
  .label
    font-weight: 600
    font-size: 14px
    line-height: 22px
    color: #272928
  .content
    color: #272928
