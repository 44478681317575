.group-setting-dialog
  position: fixed
  width: 100vw
  height: 100vh
  background: #f8f8f8
  z-index: 9999
  top: 0
  left: 0
  display: flex
  flex-flow: column nowrap
  .center
    max-width: var(--width-page-max)
    margin: 0 auto
    box-sizing: border-box
    padding: 0 12px
  .top-side
    background: #fff
  .list-header
    padding-bottom: 20px
    border-bottom: 1px solid #f8f8f8
    margin-bottom: 20px
  .group-setting-menu
    background: #fff
    flex: 1
    .group-setting-menu-item
      cursor: pointer
      font-weight: 600
      font-size: 16px
      line-height: 24px
      color: #f74f4f
