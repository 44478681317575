.app-slider
  display: flex
  flex-flow: row nowrap
  align-items: center
  user-select: none
  .icon-1
    width: 10px
    height: 10px
  .icon-2
    width: 16px
    height: 16px
