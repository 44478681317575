.detail-scroll-box
  display: flex
  width: 100%
  box-sizing: border-box
  flex-direction: column
  flex: 1
  touch-action: pan-y
  background: rgba(245,245,245,0.6)
  border: 1px solid #FFFFFF
  backdrop-filter: blur(10px)
  border-radius: 8px
  padding: 14px
  margin: 12px
  overflow: auto
  &.stop-scroll
    overflow: hidden
