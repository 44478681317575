.send-success-card
  width: 335px
  height: 456px
  background: #FFFFFF
  padding: 6px
  box-sizing: border-box
  position: relative
  .cover
    width: 136px
    height: 136px
    position: absolute
    border: 2.99146px solid #FFFFFF
    filter: drop-shadow(0px 14.9573px 21.9373px rgba(0,0,0,0.08))
    border-radius: 50%
    left: 99px
    top: 48px
  .name
    font-weight: 700
    font-size: 20px
    line-height: 120%
    text-align: center
    text-transform: capitalize
    color: #272928
    position: absolute
    top: 219px
    width: 280px
    left: 50%
    transform: translateX(-50%)
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
  .reason
    position: absolute
    text-align: center
    top: 270px
    width: 280px
    left: 50%
    transform: translateX(-50%)
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
  .footer
    position: absolute
    z-index: 2
    bottom: 0
    height: 88px
    display: flex
    flex-flow: row nowrap
    align-items: center
    padding: 0 18px
    width: 321px
    box-sizing: border-box
    justify-content: space-between
    .profile
      display: flex
      flex-flow: row nowrap
      align-items: center
      img
        width: 40px
        height: 40px
        border-radius: 50%
        margin-right: 8px
      .info
        .sender-name
          font-weight: 700
          font-size: 12px
          line-height: 14px
          color: #000000
        .des
          font-size: 11px
          line-height: 14px
          color: #000000
          transform: scale(0.90) translateX(-6%)
  .card-bg
    position: absolute
    width: 335px
    height: 456px
    left: 0
    top: 0
