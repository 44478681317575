.event-card-discover {
  width: 100%;
  border-radius: 20px;
  background: var(--color-card-bg);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: block;
  text-decoration: none;
  min-height: 158px;

  @media (max-width: 450px) {
    & {
      min-height: initial;
    }
  }


  & > .markers {
    display: none;
  }

  .event-card-action {
    display: flex;
    padding-bottom: 18px;
    flex-grow: 0;
    flex-shrink: 0;

    > button {
      display: inline-flex;
    }

    > button:nth-child(2) {
      margin-left: 12px;

    >div {
      white-space: nowrap;
      flex: 1;
    }
  }}



  .info {
    padding-top: 0px;
    &.marker {
        margin-top: 0;
    }
  }

  .markers {
    height: 20px;
    max-height: 20px;

    .marker {
      border-radius: 5px;
      background: var(--secondary-color-brand-41-light, #FEEEEE);
      color: var(--secondary-color-brand-43-normal, #F26692);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      display: inline-block;
      padding: 1px 8px;
      margin-right: 4px;
      height: 20px;
      line-height: 20px;

      &.expired {
        background: var(--secondary-color-brand-41-light, #eaeaea);
        color: var(--secondary-color-brand-43-normal, #adadad);
      }

      &.created {
        background: var(--secondary-color-brand-41-light, #e7f4ff);
        color: var(--secondary-color-brand-43-normal, #5992ff);
      }

      &.pending {
        background: #FFF7E8;
        color: #E7C54E;
      }

      &.rejected {
        background: #ffe9ef;
        color: #7a0829;
      }

      &.external {
        background: #ffede1;
        color: #fd9127;
      }

      &.private {
        background: #f8f2ff;
        color: #c863ff;
      }
    }
  }

  .post {
    position: absolute;
    width: 128px;
    height: 128px;
    top: 16px;
    right: 16px;
    overflow: hidden;

    &.mobile {
      display: none;
    }

    @media (max-width: 450px) {
      & {
        display: none;
      }

      &.mobile {
        display: block;
        width: 100px;
        height: 100px;
      }
    }


    .lazyload-wrapper {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    &.marker {
      margin-bottom: -20px;
    }
  }

  .left {
    min-width: 180px;
    margin-left: 18px;
    width: 52%;

    .details {
      min-height: 113px;
      margin-bottom: 8px;
      margin-top: 16px;
    }

    .card-apply-btn {
      border-radius: 21px;
      background: var(--primary-color-brand-16, #9EFEDD);
      display: flex;
      height: 26px;
      color: #272928;
      font-size: 12px;
      margin-right: 12px;
      font-weight: 500;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    .card-add-calendar-btn {
      border-radius: 21px;
      background: #ecf2ee;
      display: flex;
      height: 26px;
      color: #272928;
      font-size: 12px;
      margin-right: 12px;
      font-weight: 500;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      max-width: 30%;
    }

    .title {
      color: var(--color-text-main);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      max-height: 52px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      .markers {
        display: inline-block;
      }

      .marker {
        border-radius: 5px;
        background: var(--secondary-color-brand-41-light, #FEEEEE);
        color: var(--secondary-color-brand-43-normal, #F26692);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        display: inline-block;
        padding: 1px 8px;
        margin-right: 4px;
        height: 20px;
        line-height: 20px;

        &.expired {
          background: var(--secondary-color-brand-41-light, #eaeaea);
          color: var(--secondary-color-brand-43-normal, #adadad);
        }

        &.created {
          background: var(--secondary-color-brand-41-light, #e7f4ff);
          color: var(--secondary-color-brand-43-normal, #5992ff);
        }

        &.pending {
          background: #FFF7E8;
          color: #E7C54E;
        }

        &.rejected {
          background: #ffe9ef;
          color: #7a0829;
        }

        &.external {
          background: #ffede1;
          color: #fd9127;
        }

        &.private {
          background: #f8f2ff;
          color: #c863ff;
        }
      }
    }

    .tags {
      display: flex;
      flex-flow: row wrap;
      line-height: 22px;
      margin-bottom: 12px;
      min-height: 44px;

      .tag {
        color: var(--color-text-main);
        font-size: 12px;
        margin-right: 12px;
        white-space: nowrap;
        display: flex ;
        flex-flow: row nowrap;
        align-items: center;

        .dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          flex-basis: 10px;
          margin-right: 6px;
        }
      }
    }

    .host-info {
      display: flex;
      flex-flow: row nowrap;
      font-size: 14px;
      color: #272928;

      .wrap{
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
      }

      .con{
        position: relative;
        overflow: hidden;
        height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .tag{
        display: inline-block;
        margin-right: 5px;
        white-space: nowrap;
      }

    }

    .detail {
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: var(--color-text-main);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.334px;
      margin-top: 6px;
      overflow: hidden;

      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 6px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 160px;
        max-width: 80%;
      }

      i {
        margin-right: 4px;
      }
    }
  }
}

.maodao .card-apply-btn {
  background: #E1575D!important;
  color: #D6D8D7
}

@media (max-width: 650px) {
  .event-card-discover > .markers {
    display: flex;
    padding-left: 12px;
    padding-top: 18px;
    flex-flow: row wrap;
    height: auto;
    max-height: initial;

    min-width: 180px;
    width: 52%;

    &>div {
      margin-bottom: 4px;
    }
  }

  .event-card .info {
    padding-top: 0;
  }

  .event-card .left .details {
    margin-top: 10px;
  }

  .title > .markers {
    display: none!important;
  }
}
