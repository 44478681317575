.checked-panel
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  height: 500px
  .title
    font-size: 16px
    font-weight: 600
    line-height: 24px
    color: var(--text-4-headline,#272928)
    padding-top: 100px
  .des
    font-size: 16px
    line-height: 24px
    color: var(--text-4-headline,#272928)
  .info
    display: flex
    align-items: center
    flex-flow: column nowrap
    flex: 1
    justify-content: center
.use-gift
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  .btn-group
    margin-top: 30px
  & > div
    &:nth-child(1)
      margin-top: 20px
      margin-bottom: 13px
      & > div
        &:nth-child(1)
          margin-bottom: 6px
        &:nth-child(2)
          min-height: 190px
.use-gift .limit,
.use-gift .time
  padding: 8px 16px
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 15px
  color: #272928
  background: #E9F1F7
  backdrop-filter: blur(10px)
  border-radius: 16px
  margin-top: 8px
  display: flex
  flex-flow: row nowrap
  align-items: center
.use-gift .limit i,
.use-gift .time i
  margin-right: 6px
.use-gift .owner,
.use-gift .profit
  font-size: 14px
  line-height: 22px
