.bg-profile
  width: 100%
  height: 140px
  position: absolute
  left: 0
  top: 0
  overflow: hidden
  .bg-profile-inner
    filter: blur(20px)
    width: 100%
    height: 140px
    opacity: 0.3
    background-size: 140px 140px
    background-position: center
    transform: scale(2)
  .bg-profile-inner-2
    background-position: center
    background-repeat: repeat
    background-size: auto 140px
    height: 140px
    width: 100%
