.dialog-profile-qrcode
  width: 316px
  height: 486px
  position: relative
  .download-btn
    width: 100%
    height: 50px
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: center
    background: rgba(255,255,255,0.63)
    position: absolute
    left: 0
    bottom: 0
    border-radius: 0 0 12px 12px
    cursor: pointer
    .icon
      margin-right: 6px
  .card
    width: 316px
    height: 486px
    position: absolute
    left: 0
    top: 0
    background-position: center
    background-repeat: no-repeat
    background-size: 100% 100%
    border-radius: 12px
    overflow: hidden
    .bg
      display: block
      width: 316px
      height: 486px
      position: absolute
      left: 0
      top: 0
    .qrcode
      position: absolute
      left: 106px
      top: 185px
      width: 104px
      height: 104px
      img
        display: block
    .domain
      text-align: center
      width: 100%
      position: absolute
      left: 0
      top: 330px
      font-weight: 700
      font-size: 16px
      line-height: 22px
      color: #272928
    .avatar
      display: block
      width: 32px
      height: 32px
      position: absolute
      left: 142px
      top: 222px
      border-radius: 50%
