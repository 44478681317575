.issue-nftpass-page {
  position: relative;
  background: #fff;

  .issue-text {
    padding-top: 20px;
    padding-bottom: 42px;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #272928;
    }
    .des {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #272928;
      margin-top: 8px;
    }
  }

  .issue-page-wrapper {
    z-index: 2;
    max-width: var(--width-page-max);
    padding: 0 var(--width-page-edge) 0 var(--width-page-edge);
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(100vh - var(--height-nav));
    position: relative;
    padding-bottom: 30px;
  }

  .issue-page-form {
    margin-top: 30px;
  }

  .issue-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #272928;
    text-align: center;
  }

  .info {
    padding: 35px 0;
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      margin-bottom: 12px;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #272928;
    }
  }

  .input-area {
    margin-bottom: 20px;

    .issues-des {
      font-size: 12px;
      color: #272928;
      margin-bottom: 15px;
      padding-left: 10px;
    }

    .input-area-des {
      color: #999;
      font-size: 12px;
      margin-top: 12px;
    }

    .input-area-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: var(--color-text-main);
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}
