.app-vote-option-input
  .app-vote-option-input-item
    display: flex
    flex-flow: row nowrap
    margin-bottom: 24px
    .title
      min-width: 65px
      max-width: 65px
      color: var(--text-4-headline,#272928)
      font-size: 14px
      font-style: normal
      font-weight: 700
      margin-right: 6px
      margin-top: 12px
    .right
      flex: 1
      flex-flow: column nowrap
      .input-bar
        display: flex
        flex-flow: row nowrap
        align-items: center
        .issue-input-remove-btn
          min-width: 32px
          height: 32px
          max-width: 32px
          margin-left: 6px
          cursor: pointer
          border-radius: 50%
          font-size: 12px
          color: #fff
          display: flex
          flex-flow: row nowrap
          align-items: center
          justify-content: center
          font-weight: bold
          background: #ceced1
        .add-btn
          min-width: 32px
          height: 32px
          max-width: 32px
          margin-left: 6px
          cursor: pointer
      .link
        max-width: 190px
        line-height: 38px
        display: flex
        flex-flow: row nowrap
        align-items: center
        span
          color: #7492EF
          max-width: 250px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        svg
          margin-left: 4px
          background: #f1f1f1
          border-radius: 50%
          cursor: pointer
          min-width: 16px
          path
            fill: #999
      .add-link
        display: inline-flex
        height: 29px
        padding: 14px 10px
        justify-content: center
        align-items: center
        gap: 4px
        border-radius: 8px
        box-sizing: border-box
        background: var(--color-page-bg)
        cursor: pointer
        margin-top: 8px
.app-vote-option-input-dialog
  width: 100%
  height: 100%
  border-radius: 12px
  background: #fff
  box-shadow: 0 0 5px #ccc
  padding: 20px
  box-sizing: border-box
  .title
    font-size: 16px
    margin-bottom: 12px
    font-weight: bold
  .btns
    display: flex
    flex-flow: row nowrap
    margin-top: 12px
    button
      margin: 0 6px
