.checklog-user-list {
  display: flex;
  flex-flow: column nowrap;

  .user-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    border-bottom: 1px solid #F1F1F1;

    .left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }

    .checkin-by-host {
      display: flex;
      padding: 6px 8px;
      min-width: 78px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      background: var(--fill-2-medium, #F5F8F6);
      cursor: pointer;
    }

    &.uncheck {
      .left {
        color: var(--text-1-disabled, #C3C7C3);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
