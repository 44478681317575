.gen-face2face
  height: 470px
  width: 100%
  background: rgba(245,245,245,0.6)
  border: 1px solid #FFFFFF
  backdrop-filter: blur(10px)
  border-radius: 8px
  display: flex
  flex-flow: column wrap
  align-items: center
  justify-content: center
  .badge-info
    display: flex
    flex-flow: row nowrap
    align-items: center
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: #272928
    justify-content: center
    img
      min-width: 24px
      max-width: 24px
      height: 24px
      border-radius: 50%
      margin-right: 8px
    span
      flex: 1
      max-width: 50%
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
  .default-pic
    width: 192px
    height: 192px
    margin: 16px 0
  .des
    width: 245px
    font-weight: 400
    font-size: 12px
    line-height: 18px
    text-align: center
    color: #7B7C7B
    margin-bottom: 62px
