.vote-detail-page {
  background: #fff;
  min-height: 100vh;

  .host-info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 30px;
    padding: 0 12px;
    color: var(--text-4-headline, #272928);
    font-size: 14px;
    font-style: normal;

    .hoster {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: 12px;


      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 6px;
      }
    }
  }

  .center {
    max-width: var(--width-page-max);
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 12px;
  }

  .vote-card {
    box-shadow: none;

    a {
      display: none;
    }
  }

  .profile-bio {
    display: none;
  }

  .des {
    color: var(--text-4-headline, #272928);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 30px;
    padding: 0 12px;
  }
}
