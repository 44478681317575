.home-page-switcher {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  .toggle {
    margin-left: 6px;
    color: var(--color-text-sub);
  }

  .badge-page {
    color: var(--color-text-main);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    margin-right: 10px;
    padding: 0 8px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    user-select: none;

    &:hover {
      border-radius: 8px;
      border: 1px solid var(--fill-6-dark, #272928);
    }
  }

  .group-page {
    color: var(--color-text-main);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    margin-right: 6px;
    padding: 0 8px;
    height: 30px;
    box-sizing: border-box;
    user-select: none;

    &:hover {
      border-radius: 8px;
      border: 1px solid var(--fill-6-dark, #272928);
    }

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--fill-6-dark, #272928);
    cursor: pointer;

    > div {
      height: 20px;
    }

    > div:nth-child(1) {
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      max-height: 20px;
      max-width: 98px;
    }


    @media (max-width: 400px) {
      & {

        > div {
          max-width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &.active {
      background: var(--color-btn-bg-1);
      border-color: rgba(0, 0, 0, 0);
    }
  }

  .group-list {
    position: fixed;
    top: 48px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;

    .shell {
      background: var(--color-shell);
      backdrop-filter: blur(4.5px);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .list-content {
      position: absolute;
      width: 80%;
      border-radius: 16px;
      background: var(--color-card-bg);
      box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.20);
      padding: 16px 16px 0 16px;
      left: 50%;
      top: 12px;
      transform: translateX(-50%);
      display: flex;
      flex-flow: row wrap;
      max-height: 70vh;
      overflow-y: auto;


      .list-item {
        user-select: none;
        margin-right: 16px;
        border-radius: 8px;
        border: 1px solid var(--color-item-border);
        background: var(--color-page-bg-1);
        display: flex;
        padding: 8px 12px;
        align-items: center;
        cursor: pointer;
        margin-bottom: 16px;
        color: var(--color-text-main);

        img {
          height: 20px;
        }

        &.active {
          border: 1px solid var(--color-text-main);
        }

        &:hover {
          border: 1px solid #272928;
        }
      }
    }
  }
}
