.dialog-copy
  width: 100%
  height: 100%
  background: #fff
  box-shadow: 0 0 18px rgba(0,0,0,0.2)
  border-radius: 16px
  display: flex
  flex-flow: column nowrap
  align-items: center
  padding: 16px
  box-sizing: border-box
  .dialog-copy-title
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: #272928
    margin-top: 16px
  .dialog-copy-message
    font-weight: 400
    font-size: 14px
    line-height: 22px
    color: #272928
    margin-bottom: 16px
