.dialog-nft-check-in
  width: 100%
  height: 100%
  background: #000
  box-shadow: 0 4px 9px rgba(0,0,0,0.12)
  border-radius: 16px
  padding: 24px 0
  box-sizing: border-box
  .dialog-title
    font-weight: 700
    font-size: 16px
    line-height: 16px
    color: #272928
    margin-bottom: 16px
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    .close-dialog-btn
      width: 24px
      height: 24px
      display: flex
      align-items: center
      justify-content: center
      color: #D2D2D2
      cursor: pointer
      background: #F8F8F8
      border-radius: 50%
  .scan-window
    width: 100%
    height: 100%
    border-radius: 8px
    overflow: hidden
    .btns
      width: 200px
      display: flex
      flex-flow: row nowrap
      position: absolute
      left: 50%
      transform: translateX(-50%)
      bottom: 100px
      z-index: 2
      align-items: center
      justify-content: center
    div[role="button"]
      cursor: pointer
      width: 40px
      height: 40px
      display: flex
      align-items: center
      justify-content: center
      background: #7B7C7B
      border-radius: 50%
      margin: 0 20px
  &.mobile
    padding: 0
    border-radius: 0
    .scan-window
      width: 100%
      height: 100%
      border-radius: 0
      position: absolute
      top: 0
      left: 0
      .mobile-records
        width: 80%
        max-height: 400px
        padding: 12px
        background: #fff
        border-radius: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        z-index: 3
        .checkin-records-list
          max-height: 300px
