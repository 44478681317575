.point-cover-selector
  width: 100%
  height: 214px
  background: #F8F9F8
  border-radius: 16px
  align-items: center
  display: flex
  .point-cover-swiper
    height: 168px
    width: 100%
  .point-cover
    width: 168px !important
    height: 168px
    opacity: 0.5
    position: relative
    box-sizing: border-box
    border-radius: 50%
    .value
      position: absolute
      top: 79px
      left: 79px
      transform: translate(-50%,-50%)
      color: #fff
      font-size: 24px
    img
      width: 150px
      height: 150px
      position: absolute
      display: block
      top: 79px
      left: 79px
      transform: translate(-75px,-75px)
    &.active
      opacity: 1
      border-color: #9EFEDD
