.badge-detail-swiper
  padding-top: 18px !important
  .badge-detail-swiper-slide
    width: 90%
  .swiper-pagination
    position: absolute
    top: 0 !important
    bottom: auto !important
    line-height: 10px
    width: 90% !important
    margin-left: 50%
    transform: translateX(-50%)
    .swiper-pagination-bullet
      width: 16px
      border-radius: 10px
      height: 4px
      transform: scale(1) !important
    .swiper-pagination-bullet-active-main
      width: 20px
