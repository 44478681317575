.dialog-issue-prefill
  background: #F8F9F8
  width: 100%
  height: 100%
  border-radius: 16px
  padding: 16px
  box-sizing: border-box
  box-shadow: 0 0 18px rgba(0,0,0,0.2)
  .prefill-module
    background: rgba(236,238,250,0.2)
    border: 1px solid #FFFFFF
    backdrop-filter: blur(10px)
    border-radius: 8px
    padding: 12px
    margin-bottom: 8px
    .prefill-module-title
      font-style: normal
      font-weight: 600
      font-size: 16px
      line-height: 24px
      color: #272928
      margin-bottom: 15px
    .prefill-item
      width: 68px
      height: 68px
      display: flex
      align-items: center
      justify-content: center
      background: #ECF2EE
      border-radius: 4.63636px
      cursor: pointer
      img
        width: 50px
        height: 50px
        border-radius: 50%
  .create-badge-btn
    height: 91px
    background: rgba(236,238,250,0.2)
    border: 1px solid #FFFFFF
    backdrop-filter: blur(10px)
    border-radius: 8px
    display: flex
    align-items: center
    font-weight: 600
    font-size: 16px
    color: #272928
    cursor: pointer
    margin-bottom: 8px
    img
      width: 46px
      height: 46px
      margin-right: 32px
      margin-left: 26px
    .new-mark
      position: absolute
      width: 44px
      height: 20px
      background: #FFDC62
      border-radius: 3px
      display: inline-flex
      margin-left: 6px
      flex-direction: row
      justify-content: center
      align-items: center
      font-weight: 600
      font-size: 12px
      color: #272928
    .des
      font-style: normal
      font-weight: 400
      font-size: 12px
      line-height: 18px
      color: #7B7C7B
      margin-top: 3px
  .close-dialog
    height: 35px
    width: 35px
    background: #ECF2EE
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    margin: 16px auto 0 auto
    cursor: pointer
