.checkin-records
  margin-top: 16px
  .list-title
    font-size: 16px
    font-weight: 600
    line-height: 24px
    margin-bottom: 6px
  .checkin-records-list
    max-height: 200px
    min-height: 150px
    width: 100%
    overflow: auto
    margin-bottom: 12px
    position: relative
    .no-data
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%,-50%)
      font-weight: 600
      color: #7B7C7B
      height: 120px
      width: 90%
      border-radius: 12px
      font-size: 14px
      display: flex
      align-items: center
      justify-content: center
    .list-item
      width: 100%
      box-sizing: border-box
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      align-items: center
      padding: 9px 11px 9px 11px
      border-radius: 51px
      background: var(--color-page-bg)
      margin-bottom: 10px
      .list-item-left
        display: flex
        flex-flow: row nowrap
        align-items: center
        flex: 1
        img
          width: 22px
          height: 22px
          border-radius: 50%
          margin-right: 8px
