.app-textarea
  position: relative
  width: 100%
  & > span
    display: block
    min-height: 100px
    white-space: pre-wrap
    word-wrap: break-word
    color: red
    line-height: 24px
    font-size: 14px
    border-radius: 16px
    width: 100%
    font-family: 'Poppins', 'Noto Sans SC', sans-serif
    padding: 14px 10px
    box-sizing: border-box
  & > textarea
    position: absolute
    min-height: 100px
    top: 0
    left: 0
    width: 100%
    height: 100%
    line-height: 24px
    resize: none
    outline: none
    overflow: hidden
    font-family: 'Poppins', 'Noto Sans SC', sans-serif
    font-size: 14px
    background: #f6f6f6
    border-radius: 16px
    margin: 0
    padding: 14px 10px
    box-sizing: border-box
    border: 1px solid #f6f6f6
    &:focus
      border: 1px solid #9efedd
