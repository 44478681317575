.detail-transfer-accept
  width: 100%
  height: auto
  background: #fff
  display: flex
  border-radius: 12px
  box-shadow: 0 4px 9px rgba(0,0,0,0.12)
  padding: 24px 16px
  box-sizing: border-box
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  .title
    color: #333
    text-align: center
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: 24px
    margin-bottom: 20px
  .cover
    width: 90px
    height: 90px
    border-radius: 50%
    margin-bottom: 8px
  .item-name
    color: var(--text-4-headline,#272928)
    text-align: center
    font-size: 20px
    font-style: normal
    font-weight: 800
    line-height: 15.456px
    margin-bottom: 8px
  .sender-title
    margin-top: 20px
    color: var(--text-2-secondary,#7B7C7B)
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 22px
    width: 100%
  .sender
    display: flex
    align-items: center
    flex-flow: row nowrap
    padding: 9px
    width: 100%
    border-radius: 51px
    background: var(--fill-1-light-disabled,#F8F9F8)
    box-sizing: border-box
    margin-bottom: 20px
    img
      width: 22px
      height: 22px
      flex-shrink: 0
      border-radius: 50%
      margin-right: 8px
