.scan-qrcode
  position: relative
  background: #111
  width: 100%
  height: 100%
  box-sizing: border-box
  .scan-line
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: auto
    animation: scan 2s infinite linear
    transform: translateY(-100%) translate3d(0,0,0)
    opacity: 1
  #video
    width: 100%
    height: 100%
    background: #000
@keyframes scan
    0%
      top: 0
      opacity: 1
    40%
      top: 0
      opacity: 1
    100%
      top: 100%
      opacity: 0.2
      transform: translateY(100%)

@keyframes scan2
  0%
    opacity: 1
    transform: translateY(-100%)
  100%
    transform: translateY(100%)
    opacity: 0
