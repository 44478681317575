.bind-email {
  position: relative;

  .skip {
    color: var(--text-4-headline, #272928);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: right;
    padding: 12px 12px 0 0;
    cursor: pointer;
  }

  .login-page-back {
    padding: 0 12px;
    z-index: 999;
    position: relative;
  }

  .login-page-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    position: absolute;
    background: linear-gradient(180deg,#9efedd,rgba(237,251,246,0));
    opacity: .2;
    pointer-events: none;
    z-index: 1;
  }

  .login-page-wrapper {
    z-index: 2;
    max-width: var(--width-page-max);
    padding: 0 var(--width-page-edge);
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    position: relative;
  }

  .login-page-content {
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #272928;
    }
    .des {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #4F5150;
      margin-bottom: 70px;
    }

    &.code {
      input {
        touch-callout: none;
        -webkit-touch-callout: none;
        -ms-touch-callout: none;
        -moz-touch-callout: none;
      }
    }
  }
}
