.list-group-member
  margin-bottom: 12px

  .action-left
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: space-between
    white-space: nowrap
    color: var(--color-text-main)

    svg
      min-width: 20px
      cursor: pointer

    span
      margin-right: 12px

  .action
    display: flex
    justify-content: flex-end
    font-size: 12px

    span
      font-weight: normal
      margin-left: 4px


.member-list-joined-label
  min-width: 60px
  height: 22px
  background: #ECF2EE
  border-radius: 16px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  font-size: 12px

.member-manage-btn
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

.side-member-count
  display: flex
  height: 40px
  padding: 0 8px
  justify-content: center
  align-items: center
  border-radius: 30px
  border: 1px solid var(--Line-1-Light, #F1F1F1)
  color: var(--color-text-main)
  font-size: 14px
  cursor: pointer
