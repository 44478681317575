.event-page {
  .event-title {
    padding: 20px;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #272928;
    margin-bottom: 3px;

    .event-title-center {
      padding: 0 12px;
      max-width: 875px;
      margin: 0 auto;
    }
  }

  .event-badgelet-list {
    padding: 0 12px;
    max-width: 875px;
    margin: 12px auto;
  }
}
