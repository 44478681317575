.dialog-cropper
  width: 100%
  height: 100%
  background: #fff
  box-shadow: 0 1.9878px 18px rgba(0,0,0,0.2)
  border-radius: 16px
  padding: 16px
  box-sizing: border-box
  .dialog-title
    font-weight: 700
    font-size: 16px
    line-height: 16px
    color: #272928
    margin-bottom: 16px
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    .close-dialog-btn
      width: 24px
      height: 24px
      display: flex
      align-items: center
      justify-content: center
      color: #D2D2D2
      cursor: pointer
      background: #F8F8F8
      border-radius: 50%
  .btns
    display: flex
    flex-flow: row nowrap
    justify-content: flex-end
    margin-top: 10px
    button
      width: 77px
  .cropper-face
    background: none
  .cropper-container
    transform: rotate(0,0,0)
.dialog-cropper .cropper-view-box,
.dialog-cropper .cropper-crop-box
  border-radius: 50%
