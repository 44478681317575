.header-search
  display: flex
  flex-flow: row nowrap
  align-items: center
  .icon-search
    color: var(--color-text-main)
  .search-confirm-btn
    font-size: 12px
    background: #6cd7b2
    color: #fff
    display: flex
    align-items: center
    justify-content: center
    height: 22px
    width: 36px
    border-radius: 26px
    margin-right: -3px
    cursor: pointer
    .icon-search
      color: #fff
  .search-cancel-btn
    font-size: 12px
    color: #6cd7b2
    margin-left: 8px
    white-space: nowrap
    cursor: pointer
  div[data-baseweb="input"]
    padding-right: 0
    border-radius: 30px
    input
      padding-top: 6px
      padding-bottom: 6px
      line-height: normal
      font-size: 12px
