.profile-page {
  background: var(--color-page-bg);
  min-height: 100%;
  position: relative;

  .profile-user-name {
    display: none;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    color: #272928;
    margin-bottom: 32px;
  }

  .center {
    max-width: var(--width-page-max);
    margin: 0 auto;
    padding: 0 12px;
  }

  .top-side {
    padding-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .up-side {
    background: var(--color-page-bg-1);
    padding-bottom: 20px;
  }

  .down-side {
    padding-top: 12px;

    .maodao-event {
      .event-card {
        width: 100%;
        margin-bottom: 10px;
        max-width: initial;
      }
    }

    .maodao-nft {
      padding: 0 12px;
    }
  }

  .slot_1 {
    margin-top: 72px;

    .maodao-position {
      color: var(--color-text-sub);
      margin-bottom: 8px;
    }

    .maodao-tag {
      padding: 0 7px;
      height: 24px;
      background: var(--color-page-bg);
      color: var(--color-text-main);
      font-size: 12px;
      display: inline-flex;
      line-height: 24px;
      border-radius: 4px;
      margin-top: 8px;
      margin-right: 8px;
    }

    @media (min-width: 450px) {
      margin-top: 124px;
    }
  }

  .slot_2 {
    margin-top: 20px;

    .name {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon-sendfasong {
    margin-right: 12px;
    font-size: 16px;
  }

  .profile-setting {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    color: var(--color-text-main);

    .profile-setting-btn {
      margin-left: 10px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-text-main);
    }
  }

  .profile-tab {
    div[role="tabpanel"] {
      padding-left: 0;
      padding-right: 0;
    }

    .list-vote {
      margin-bottom: 30px;
    }

    .title-member {
      color: var(--color-text-main);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 12px;
      align-items: center;

      .action {
        .create-vote-btn {
          color: var(--color-text-main);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          cursor: pointer;
        }
      }
    }


    >div[data-baseweb="tabs"] {
      >div[role="tablist"] {
        display: block;
        white-space: nowrap;
        overflow: auto;
        padding-left: 12px;
        background: none;

        &::-webkit-scrollbar {
          display: none;
        }

        > div[role="tab"] {
          padding: 8px 16px;
          border: 0;
          border-radius: 8px;
          display: inline-flex;
          margin: 0 6px 0 0;
          color:var(--color-text-main);

          &[aria-selected="true"] {
            background: #272928;
            border-bottom: 2px solid #272928;
            color: #fff;
          }
        }
      }

      >div[role="tabpanel"] {
        padding: 16px 12px;

        div[role="tablist"] {
          padding: 0!important;
        }
      }
    }
  }
}

@media (min-width: 450px) {
  .profile-page {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-top: 90px;
    max-width: 942px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    background: var(--color-page-bg);

    .profile-user-name {
      display: block;
    }

    .up-side {
      min-width: 375px;
      max-width: 375px;
      margin-right: 32px;
      position: relative;
      background: var(--color-page-bg-1);
    }

    .top-side {
      margin-top: -30px;
      padding-top: 0;
    }

    .down-side {
      flex: 1;
      min-width: 0;
      padding-top: 0;
    }
  }
}
