.user-assets-list
  width: calc(100% + 12px)
  .list-content
    display: flex
    flex-flow: row wrap
    & > div
      min-width: 162px
      max-width: 182px
      margin-right: 12px
      flex-basis: 162px
      flex-grow: 1
  .list-action
    display: flex
    margin-top: 12px
    justify-content: center
    button
      display: inline-flex
      min-width: 126px
      width: auto
      background: #ECF2EE
  &.compact
    .list-content
      & > div
        flex-basis: 106px
        min-width: 106px
        max-width: 118px

@media (min-width: 750px)
    .user-assets-list .list-content > div
      max-width: 153px
      min-width: 153px

    .user-assets-list .list-content > div > div
        min-width: initial

