.confirm-domain
  border-bottom: 6px solid #9efedd
  display: inline-flex
  width: auto
  margin: 0 auto
  position: relative
  left: 50%
  transform: translate(-50%,0)
  span
    padding: 0 10px
    top: 5px
    position: relative
    text-align: center
    color: #272928
    font-size: 16px
    font-weight: 600
    line-height: 24px
