.issue-type-select-gift
  padding: 16px
  background: rgba(245,245,245,0.6)
  border: 1px solid #FFFFFF
  backdrop-filter: blur(10px)
  border-radius: 8px
  .title
    font-style: normal
    font-weight: 600
    font-size: 20px
    line-height: 28px
    text-align: center
    color: #272928
  .item
    display: flex
    flex-flow: row nowrap
    margin-top: 19px
    justify-content: space-between
    align-items: center
    min-height: 32px
    margin-bottom: 8px
    .item-title
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 22px
      color: #272928
    .item-value
      display: flex
      flex-flow: row nowrap
      height: 32px
      align-items: center
      .unlimited
        font-style: normal
        font-weight: 600
        font-size: 14px
        line-height: 22px
        color: #272928
      input
        background: #ECF2EE
        border-radius: 5px
        border: 1px solid #ecf2ee
        min-width: 66px
        max-width: 66px
        height: 32px
        text-align: center
        box-sizing: border-box
        outline: none
        &:focus
          border: 1px solid #9EFEDD
          background: #fff
      .date-select
        min-width: 100px
        div[data-baseweb="input"]
          background: none
          border: 0
          div[data-baseweb="base-input"]
            background: none
            border: 0
            width: 100%
            input
              background: none
              border: 0
              font-size: 12px
              min-width: 100%
              font-weight: 600
  div[data-baseweb="input"]
    background-color: #ECF2EE
    border-radius: 10px
    div[data-baseweb="base-input"]
      background-color: #ECF2EE
      input
        padding: 6px 12px
    div
      &:last-child
        background-color: #ECF2EE
  .issue-input-add-btn
    background: #fff
    box-sizing: border-box
  .actions
    margin-top: 32px
    .send-later
      text-align: center
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 22px
      color: #272928
      margin-top: 16px
      cursor: pointer
