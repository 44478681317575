.showText
  .event
    color: #7492EF !important
    text-decoration: none
  .link
    color: #7492EF !important
    text-decoration: none
  img
    display: block
    margin: 0 auto
    max-width: 80%
