.member-manage-dialog
  position: fixed
  width: 100%
  height: 100%
  background: #f8f8f8
  z-index: 9999
  top: 0
  left: 0
  .content
    flex: 1
    background: #f8f8f8
    padding: 12px 0
    overflow: auto
  .center
    max-width: var(--width-page-max)
    margin: 0 auto
    box-sizing: border-box
    padding: 0 12px
    width: 100vw
  .top-side
    background: #fff
    display: flex
    flex-flow: column
    height: 100%
    & > div[data-baseweb="tabs"]
      display: flex
      flex-flow: column nowrap
      flex: 1
      overflow: hidden
  div[role="tabpanel"]
    flex: 1
    overflow: auto
    .center
      padding: 0
    div[role="tabpanel"]
      overflow: visible
  .list-header
    padding-bottom: 20px
    border-bottom: 1px solid #f8f8f8
  .dialog-bottom
    padding: 12px
