.dialog-confirm-domain
  width: 100%
  height: 100%
  border-radius: 12px
  background: #fff
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12)
  padding: 32px 15px 20px
  box-sizing: border-box
  .title
    font-size: 16px
    font-weight: 400
    line-height: 24px
    margin-bottom: 30px
    text-align: center
    color: #606266
  .btns
    display: flex
    flex-flow: row nowrap
    align-items: center
    margin-top: 30px
    button
      &:not(:last-child)
        margin-right: 16px
