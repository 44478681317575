.zugame-role-dialog {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  color: var(--text-4-headline, #272928);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}

.icon {
  font-size: 60px;
  margin-bottom: 20px;
}

.name {
  color: var(--text-4-headline, #272928);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}
