.list-title
  display: flex
  flex-flow: row nowrap
  align-items: center
  justify-content: space-between
  margin-bottom: 10px
  padding: 0 12px
  & > .label
    display: flex
    align-items: center
    & > .text
      font-weight: 600
      font-size: 16px
      line-height: 24px
      color: #272928
    .action
      margin-left: 4px
  .amount
    display: flex
    flex-flow: row nowrap
    align-items: center
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: #272928
    & > div
      font-weight: 400
      font-size: 14px
      line-height: 22px
      color: #272928
      margin-left: 4px
