.event-site-input {
  margin-bottom: 30px;
  border-bottom: 1px solid #F1F1F1;
  padding-bottom: 20px;

  #map {
    width: 0;
    height: 0;
    opacity: 0;
    display: block;
  }

  .input-area-sub-title {
    color: var(--color-text-main);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 4px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .remove-btn {
      font-size: 12px;
      padding-right: 12px;
      font-weight: normal;
      color: #dc5f5f;
      cursor: pointer;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-text-main);
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .selector {
    position: relative;
    margin-top: 12px;

    i {
      position: absolute;
      z-index: 9;
      left: 12px;
      font-size: 18px;
      top: 13px;
    }

    div[data-baseweb="select"] {

      >div {
        font-size: 14px;
        border: 0;
        color: rgb(39, 41, 40);
        padding-left: 24px;
        border-radius: 12px;
      }
      div[value], input {
        font-size: 14px;
        border: 0;
        color: rgb(39, 41, 40)
      }
    }
  }

  .delete {
    cursor: pointer;
    margin-right: -12px;
  }

  .custom-selector {
    position: relative;

    .shell {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: rgba(0,0,0,0);
      left: 0;
      top: 0;
      z-index: 9;
    }

    div[data-baseweb="input"] {
      position: relative;
      z-index: 99;
    }

    .search-res {
      position: absolute;
      width: 100%;
      border-radius: 18px;
      background: var(--color-card-bg);
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
      z-index: 888;
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;



      .res-list {
        height: 200px;
        overflow: auto;
        margin-top: 12px;
      }

      .empty-list {
        height: 200px;
        overflow: auto;
        margin-top: 12px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background: #f6f6f6;
        border-radius: 12px;
      }

      .search-res-item {
        padding: 12px;
        cursor: pointer;
        border-radius: 6px;

        &:hover {
          background: #F5F8F6;
        }

        .search-title {
          color: var(--color-text-main);
          font-size: 16px;
          margin-bottom: 4px;
        }
        .search-sub-title {
          color: var(--fill-5-dark, #7B7C7B);
          font-size: 12px;
        }
      }
    }
  }

  .add-override-btn {
    display: inline-flex;
    padding: 12px;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    border-radius: var(--Radii-radius-button, 8px);
    background: var(--Fill-2-Medium, #F7F7F7);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Text-4-Headline, #272928);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    margin-top: 12px;
    &:hover {
      opacity: 0.7;
    }
  }

  .override-item {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 12px;
    align-items: center;

    .info {
      cursor: pointer;
      border-radius: 10px;
      background: var(--Fill-1-Light-Disabled, #F8F9F8);
      padding: 10px;
      flex: 1;
      margin-right: 12px;
      display: flex;
      flex-flow: row nowrap;
      font-size: 16px;
      align-items: center;
      >div {
        flex: 1;
      }

      i {
        font-size: 16px;
      }
    }

    >svg {
      cursor: pointer;
    }
  }
}

.slots {
  flex: 1;
  margin-left: 6px;
  > div[data-baseweb=select] * {
    border: 0 !important;
    color: var(--text-2-secondary, #7B7C7B);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
