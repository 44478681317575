.error-page {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: calc(75vh - var(--height-nav));
  max-width: var(--width-page-max);
  margin: 0 auto;
  padding: 0 20px;
}
