.create-gift-page {
  position: relative;
  background: var(--color-page-bg-1);

  .create-badge-page-wrapper {
    z-index: 2;
    max-width: var(--width-page-max);
    padding: 0 var(--width-page-edge) 0 var(--width-page-edge);
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(100vh - var(--height-nav));
    position: relative;
    padding-bottom: 30px;
    box-sizing: border-box;
  }

  .create-badge-page-form {
    margin-top: 30px;
  }

  .create-badge-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #272928;
    text-align: center;
  }

  .input-area {
    margin-bottom: 20px;

    .input-area-des {
      color: #999;
      font-size: 12px;
      margin-top: 12px;
    }

    .input-area-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: var(--color-text-main);
      margin-bottom: 10px;
      margin-left: 10px;
    }

    .toggle-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .label {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: #272928;
        margin-bottom: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .prefill-preview {
    height: 205px;
    background: var(--color-page-bg);
    border-radius: 8px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin-top: 26px;

    .avatar {
      height: 84px;
      width: 84px;
      border: 2.28731px solid #FFFFFF;
      filter: drop-shadow(0px 11.4365px 16.7736px rgba(0, 0, 0, 0.08));
      border-radius: 50%;
      margin-bottom: 16px;
    }

    .name {
      color: #272928;
      font-weight: 700;
      font-size: 20px;
      line-height: 15px;
    }

    .create-time {
      height: 32px;
      background: #ECF2EE;
      border-radius: 33px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #272928;

      .icon-clock {
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }
}

