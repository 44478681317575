.all-day {
  display: inline-flex;
  align-items: center;
  flex-flow: row nowrap;
  color: var(--text-2-secondary, #7B7C7B);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  user-select: none;
  margin-right: 16px;

  label[data-baseweb="checkbox"] {
    transform: scale(0.8);
  }
}


.all-day-repeat {
  display: flex;
  align-items: center;

  &.time-slot {
    margin-top: 8px;
    padding-left: 44px;
  }

  div[data-baseweb="select"] {
    width: auto;

    * {
      border: 0 !important;
      background: none;
      color: var(--text-2-secondary, #7B7C7B);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    * {
      padding: 0;
    }
  }
}

.repeat-counter {
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-text-main);
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .repeat-counter-input {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--Text-4-Headline, #272928);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    input {
      display: flex;
      width: 66px;
      padding: 10px 0;
      justify-content: center;
      align-items: center;
      color: var(--Text-4-Headline, #272928);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      outline: none;
      box-sizing: border-box;
      border-radius: 8px;
      background: #f6f6f6;
      border: 0;
      margin-right: 8px;
    }
  }
}

.app-date-input-v2 {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;
  width: 100%;
  height: 44px;

  &.second {
    margin-top: 8px;
  }

  .slots {
    flex: 1;
    margin-left: 6px;
    > div[data-baseweb=select] * {
      border: 0 !important;
      color: var(--text-2-secondary, #7B7C7B);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    div[data-baseweb="input"] {
      border: 0;
      background: #F8F8F8;
      input {
        border: 0;
        background: #F8F8F8;
        font-size: 14px;
      }
    }
  }

  .from-label {
    margin-left: 8px;
  }

  .duration {
    color: var(--color-text-sub);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
    white-space: nowrap;
    flex: 1;
  }


  .time-input {
    max-width: 86px !important;
    min-width: 60px !important;
    height: 44px;
    margin-left: 8px;

    div[data-baseweb="select"] {
      > div {
        border: 0;
        font-size: 14px;
        background: var(--color-input-bg);
        color: var(--color-text-main);

        > div:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .time-input-ending {
    margin-left: 0;
  }

  .date-input {
    position: relative;
    border-radius: 10px;
    background: var(--color-input-bg);
    min-width: 120px;
    height: 44px;
    display: flex;
    align-items: center;

    > div:nth-child(3) {
      background: red;
      position: absolute;
      left: 0;
      max-width: 110px;
      height: 44px;
      opacity: 0;
    }

    .show-date {
      margin-left: 24px;
      color: var(--color-text-main);
      font-size: 14px;
      font-weight: 400;
    }

    > svg {
      position: absolute;
      left: 6px;
      top: 13px;
      z-index: 9;
    }
  }

  .arrow {
    display: block;
    margin: 0 8px;
    min-width: 16px;
  }
}

ul[data-baseweb="menu"] {
  min-width: 70px;
}

ul[role="listbox"] {
  min-width: 200px;
  white-space: nowrap;
}

.timezone {
  margin-bottom: 8px;
  margin-top: 8px;

  div[data-baseweb="select"] {
    width: auto;

    * {
      border: 0 !important;
      color: var(--text-2-secondary, #7B7C7B);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

  }
}
