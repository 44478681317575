.address-list-dialog
  position: fixed
  width: 100%
  height: 100%
  background: #f8f8f8
  z-index: 9999
  top: 0
  left: 0
  .user-search
    padding: 10px
    max-width: 450px
    width: 100%
    box-sizing: border-box
    margin: 0 auto
    input
      background: #fff
  .user-search-result
    flex: 1
    padding: 10px
    overflow: auto
    box-sizing: border-box
    margin: 0 auto
    width: 100%
    background: #f8f8f8
  .center
    max-width: var(--width-page-max)
    margin: 0 auto
    box-sizing: border-box
    padding: 0 12px
  .top-side
    background: #fff
    display: flex
    flex-flow: column
    height: 100%
    & > div[data-baseweb="tabs"]
      display: flex
      flex-flow: column nowrap
      flex: 1
      overflow: hidden
  div[role="tabpanel"]
    flex: 1
    overflow: auto
    .center
      padding: 0
    div[role="tabpanel"]
      overflow: visible
  .list-header
    padding-bottom: 20px
    border-bottom: 1px solid #f8f8f8
  .dialog-bottom
    padding: 12px
