.detail-badge-transferable
  display: flex
  width: 135px
  padding: 8px
  justify-content: center
  align-items: center
  gap: 8px
  border-radius: 51px
  background: var(--fill-1-light-disabled,#F8F9F8)
  color: var(--text-4-headline,#272928)
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 22px
  cursor: pointer
