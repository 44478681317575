.share-qrcode-card
  width: 100%
  left: 20px
  top: 234.5px
  background: rgba(245,245,245,0.6)
  border: 1px solid #FFFFFF
  backdrop-filter: blur(10px)
  border-radius: 8px
  padding-bottom: 40px
  .inner
    display: flex
    flex-flow: column wrap
    align-items: center
    justify-content: center
  .card-header
    display: flex
    flex-flow: row nowrap
    align-items: center
    margin-top: 28px
    justify-content: center
    width: 100%
    .cover
      width: 64px
      height: 64px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      margin-right: 16px
      position: relative
      overflow: hidden
      img
        width: 64px
        height: 64px
      .point
        position: absolute
        width: 100%
        border-radius: 50%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        color: #fff
        font-size: 12px
        text-align: center
    .sender-info
      text-align: left
      max-width: 50%
      .badge-name
        font-weight: 600
        font-size: 16px
        line-height: 24px
        color: #272928
        white-space: nowrap
        width: 100%
        overflow: hidden
        text-overflow: ellipsis
      .des
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 20px
        color: #272928
  .card-title
    font-weight: 600
    font-size: 20px
    line-height: 28px
    text-align: center
    color: #272928
    margin-top: 26px
    margin-bottom: 8px
  .card-recommended
    display: flex
    flex-flow: row nowrap
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #272928
    margin-bottom: 10px
    img
      width: 20px
      height: 20px
      margin-left: 10px
  .code
    width: 192px
    height: 192px
    background: #fff
    display: flex
    align-items: center
    justify-content: center
  .expired-mask
    position: absolute
    width: 100%
    height: 100%
    background: rgba(255,255,255,0.9)
    backdrop-filter: blur(5px)
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    flex-flow: column nowrap
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 20px
    color: #272928
    button
      margin-top: 16px
.share-qrcode-card .limit,
.share-qrcode-card .time
  padding: 8px 16px
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 15px
  color: #272928
  background: #E9F1F7
  backdrop-filter: blur(10px)
  border-radius: 16px
  margin-top: 8px
  display: flex
  flex-flow: row nowrap
  align-items: center
.share-qrcode-card .limit i,
.share-qrcode-card .time i
  margin-right: 6px
