.dialog-public-image
  width: 100%
  height: 100%
  border-radius: 16px
  background: #fff
  box-shadow: 0px 2px 18px rgba(0,0,0,0.2)
  padding: 20px 18px
  box-sizing: border-box
  .dialog-title
    display: flex
    flex-flow: row nowrap
    font-weight: 700
    font-size: 16px
    line-height: 15px
    color: #272928
    justify-content: space-between
    align-items: center
    margin-bottom: 16px
  .dialog-close-btn
    width: 24px
    height: 24px
    background: #F8F8F8
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
  .upload-image-btn
    display: flex
    flex-flow: column nowrap
    background: #F8F9F8
    border-radius: 16px
    height: 146px
    justify-content: center
    align-items: center
    color: #4F5150
    cursor: pointer
    margin-bottom: 25px
    img
      width: 62px
      height: 62px
    .btn-main-text
      font-size: 12px
      line-height: 15px
      font-weight: 700
      margin-top: 6px
    .btn-sub-text
      font-size: 12px
  .public-pic-title
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: #272928
    margin-bottom: 5px
  .public-pic-item
    width: 88px
    height: 88px
    background: #F5F8F6
    border-radius: 6px
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    img
      width: 64px
      height: 64px
      border-radius: 50%
